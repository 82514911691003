// Vue Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store';

import { Auth } from 'aws-amplify'
Vue.use(Router)


// Set up Routes
const routes = [
  {
    path: '',
    name: 'landing',
    component: () => import('@/router/views/Landing'),
    meta: { requiresAuth: false }
  },
  {
    path: '/lookup',
    name: 'lookup',
    component: () => import('@/router/views/Profile'),
    meta: { requiresAuth: true }
  },
  {
    path: '/applications',
    name: 'applications',
    component: () => import('@/router/views/Applications'),
    meta: { requiresAuth: true }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/router/views/SignOut'),
    meta: { requiresAuth: false }
  },
  // {
  //   path: '/docs',
  //   name: 'docs',
  //   component: () => import('@/router/views/Docs'),
  //   meta: { requiresAuth: false }
  // }
]

// Initiate router
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

router.beforeEach((to, from, next) => {
  const authRequired = to.matched.some(record => record.meta.requiresAuth)

  if (authRequired) {
    return store.dispatch('validate').then((validUser) => {
      validUser ? next() : Auth.federatedSignIn({provider: 'Azure'});
    })
  } else {
    return store.dispatch('validate').then(next())
  }
  
})

export default router;