<template>
  <v-app class="containing-element">
    <byu-header>
      <span slot="site-title"><a href="/">Provisioning</a></span>
      <byu-menu slot="nav">
        <a href="/lookup">Person Finder</a>
        <a href="/applications">Applications</a>
        <!-- <a href="/docs">Documentation</a> -->
      </byu-menu>
      <byu-user-info slot="user">
        <a 
          slot="login" 
          @click="loginUser"
        >Sign In</a>
        <span
          v-if="$store.getters.loggedIn"
          slot="user-name"
        >{{ givenName }}</span>
        <a 
          slot="logout"
          @click="logoutUser"
        >Sign Out</a>
      </byu-user-info>
    </byu-header>
    <v-content>
      <router-view />
    </v-content>
    <byu-footer />
  </v-app>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  name: 'App',
  methods: {
    loginUser() {
      Auth.federatedSignIn({provider: 'Azure'})
    },

    logoutUser() {
      this.$store.dispatch('setLoggedOut')
      Auth.signOut()
    },
  },

  computed: {
    givenName () {
      return this.$store.state.user.attributes.given_name
    }
  }
};

</script>

<style>
  html, body {
    height: 100%;
      font-family: 'Public Sans', 'Noto Sans', 'Open Sans', Helvetica, Arial, sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
      font-family: 'HCo Ringside Narrow SSm', 'Open Sans', Helvetica, Arial, sans-serif;
  }
  
  .containing-element {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .page-content {
      flex-grow: 1;
  }
</style>
