import Vue from 'vue'
import Vuex from 'vuex'
import { Auth } from 'aws-amplify'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    pendingAuth: false
  },

  getters: {
    // Check Auth State
    loggedIn(state) {
      return !!state.user
    },
  }, 

  mutations: {
    // Set User state
    SET_CURRENT_USER(state, payload) {
      Auth.currentAuthenticatedUser()
      .then(data => {
        payload.userGroups = data.signInUserSession.accessToken.payload['cognito:groups']
      })

      state.user = payload
      state.pendingAuth = false
    }
  },

  actions: {
    setLoggedIn(context, payload) {
      context.commit('SET_CURRENT_USER', payload)
    },

    setLoggedOut(context) {
      context.commit('SET_CURRENT_USER', null)
    },

    // Validate User Session
    validate({ commit }) {  
      return Auth.currentUserInfo()
        .then(response => {
          commit('SET_CURRENT_USER', response)
          return response
        })
        .catch(error => {
          console.log(error)
          commit('SET_CURRENT_USER', null)
          return null
        })
    }
  }

})
