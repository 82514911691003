import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'

// import plugin
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'
// Vuetify's CSS styles 
import 'vuetify/dist/vuetify.min.css'

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})

import Amplify, * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import awsconfig from './aws-exports'

Amplify.configure(awsconfig);

Vue.use(AmplifyPlugin, AmplifyModules)
Vue.use(Vuelidate)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')


import { Hub, Logger } from 'aws-amplify';

const logger = new Logger('Auth-Logger');

const listener = (data) => {
  switch (data.payload.event) {
    case 'signIn':
      logger.error('user signed in'); //[ERROR] My-Logger - user signed in
      store.dispatch('validate').then(
        router.push({name: 'lookup'})
      )
      break;
      
    case 'signOut':
      logger.error('user signed out');
      store.dispatch("setLoggedOut")
      break;

    case 'signIn_failure':
      logger.error('user sign in failed');
      break;
  }
}

Hub.listen('auth', listener);